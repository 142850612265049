import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { Common } from "../Common";
import { Lightbox, Tinymce } from "@/components";
import { InstructionsModel } from "@/models";
import { ErrorCode, ErrorMessage } from "@/constant";

export class InputForm {
  iid = '';
  content = '';
}

type response = { [key: string]: unknown };

@Component<AdminInstructionsEditController>({
  components: {
    SideNav, Tinymce, Lightbox
  }
})
export default class AdminInstructionsEditController extends Vue {
  private doubleClick = false;
  private inputForm = new InputForm();
  private token = window.localStorage.getItem('adminToken') as string;
  private errorMsg = "";
  private openLB = false;
  private errNo = 0;

  public async created() {
    const item: { [key: string]: string } = {
      iid: this.$route.query.iid as string,
      token: this.token,
    }
    const data = await InstructionsModel.get(item) as response;
    this.assignDataToForm(data);
  }

  private assignDataToForm(data: response) {
    Common.checkToken(data.ERR_CODE as number);

    const item = data.info as { [key: string]: string };

    this.inputForm.iid = item.iid;
    const editor: any = this.$refs.editor;
    editor.setContent(item.content);
    this.inputForm.content = item.content;
  }

  private async validateAndSubmit() {
    if (!this.doubleClick) {
      this.doubleClick = true;

      const formobj: HTMLFormElement = document.getElementById('inputForm') as HTMLFormElement;
      const data = new FormData(formobj); //創建空的Formdata object

      data.append('token', this.token);
      data.append('iid', this.inputForm.iid);
      data.append('content', this.inputForm.content);

      this.errNo = await InstructionsModel.edit(data);
      Common.checkToken(this.errNo);
      this.openLB = true;
      this.doubleClick = false;
      this.errorMsg = ErrorMessage[this.errNo];
    }
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
      this.$router.push("/admin/instructions");
    }
  }

}
